<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.advancePaymentCode.$error }">
                                <label for="code">Số tạm ứng</label>
                                <md-input name="code" v-model="entity.advancePaymentCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.advancePaymentCode.required">Vui lòng nhập số tạm ứng</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.advancePaymentDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.advancePaymentDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.advancePaymentDate.required">Vui lòng chọn ngày tạo</span>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.note.$error }">
                               <label for="reason">Nội dung</label>
                               <md-input name="reason" v-model="entity.note"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.note.required">Vui lòng nhập nội dung tạm ứng</span>
                           </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="note">Tổng tạm ứng</label>
                                    <md-input name="amount" :value="formatNumber(entity.amount)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-estimate" class="tab-content" md-label="Dự toán">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:120px;">#</th>
                                                            <th style="width:30%;">Số dự toán</th> 
                                                            <th style="width:20%;">Người dự toán</th> 
                                                            <th style="width:20%;">Ngày dự toán</th> 
                                                            <th style="width:15%;">Tổng dự toán</th>
                                                            <th style="width:10%">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.estimateList" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.estimate.estimateCode" class="form-control" type="text">
                                                                    <md-button @click="openEstimateList(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm số dự toán</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    {{item.estimate.staff.fullName}}
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    {{item.estimate.estimateDate}}
                                                                </div>
                                                            </td> 
                                                            <td>
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(item.estimateAmount)}} VNĐ</div>
                                                            </td>
                                                            <td class="grid-action">
                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết tạm ứng">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div style="overflow-x: auto; width: 100%;">
                                                    <table class="data-table-2" style="width: 1500px;"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th rowspan="2" style="width:50px;">#</th> 
                                                                <th rowspan="2" style="width:180px;">Dự toán</th> 
                                                                <th rowspan="2" style="width:240px;">Tên phí</th> 
                                                                <th rowspan="2" style="width:120px;">Loại</th>
                                                                <th rowspan="2" style="width:120px;">Đơn vị</th>
                                                                <th rowspan="2" style="width:100px;">Số lượng</th>
                                                                <th colspan="3" style="width:340px; background-color: #B4E380;">Dự toán</th>
                                                                <th colspan="3" style="width:340px; background-color: #FFD18E;">Tạm ứng</th>
                                                            </tr>
                                                            <tr> 
                                                                <th class="right" style="width:120px; background-color: #B4E380;">Số tiền</th>
                                                                <th class="right" style="width:100px; background-color: #B4E380;">VAT</th>
                                                                <th class="right" style="width:120px; background-color: #B4E380;">Thành tiền</th>
                                                                <th class="right" style="width:120px; background-color: #FFD18E;">Số tiền</th>
                                                                <th class="right" style="width:100px; background-color: #FFD18E;">VAT</th>
                                                                <th class="right" style="width:120px; background-color: #FFD18E;">Thành tiền</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <span>{{ item.estimate.estimateCode }}</span>
                                                                </td>
                                                                <td class="left">
                                                                    <span>{{ item.fee.feeName}}</span>
                                                                </td>
                                                                <td class="center">
                                                                    {{ getFeeType(item.type) }}
                                                                </td>
                                                                <td class="center">
                                                                    <span>{{ item.unit.unitName }}</span>
                                                                </td>
                                                                <td class="center">
                                                                    {{item.quantity}}
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimatePrice)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimateVat)}}%</div> 
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimateAmount)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.advancePaymentPrice)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.advancePaymentVat)}}%</div> 
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.advancePaymentAmount)}}</div> 
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:15%;">Bộ phận/phòng</th> 
                                                            <th style="width:20%;">Người phê duyệt</th> 
                                                            <th style="width:20%;">Tiêu đề</th>
                                                            <th style="width:20%;">Ghi chú</th> 
                                                            <th style="width:15%;">Thứ tự</th> 
                                                            <th style="width:150px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.dept.name" class="form-control" type="text">
                                                                    <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                    <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.title" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.note" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeApprove"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <estimateList ref="estimateList" @close="closeEstimate"/>
   </div>
</template>
<script>
    import orderHeavyAdvancePaymentService from '@/api/orderHeavyAdvancePaymentService';
    import orderHeavyEstimateService from '@/api/orderHeavyEstimateService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import estimateList from '@/components/popup/_OrderHeavyEstimateList.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            estimateList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật tạm ứng chi phí làm hàng'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, status: 0, details: [], amount: 0, estimateList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, advancePaymentCode: '', advancePaymentDate: common.dateNow, pay: 0, payFee: 0, payService: 0, payOnBehalf: 0 },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                estimate: { id: 'id_' + common.getFakeId(), pay: 0, estimateAmount: 0, estimateId: 0, orderId: 0, order: {orderCode: ''}, estimate: { estimateCode: '', estimateDate: '', staff: { fullName: '' }}},
                estimateList: [],
                vat: common.vatF,
                currency: common.currencyF,
                staffName: '',
                staffs: [],
                types: common.typeFee,
                catFee: 0,
                typeOfTransport: common.typeOfTransport
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật tạm ứng chi phí làm hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới tạm ứng chi phí làm hàng';
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.estimateList.push(this.estimate);
               this.getApproveSetting();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calTotal(){
                this.entity.amount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    var item = this.entity.details[i];
                    this.entity.amount = parseFloat(this.entity.amount) + parseFloat(item.advancePaymentAmount);
                }
            },

            getTypeName(transportTypeId){
                return this.typeOfTransport.filter((obj) => obj.id == transportTypeId)[0].text;
            },

            getCode(staffId){
                this.setLoading(true);
                orderHeavyAdvancePaymentService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.advancePaymentCode = response.data.code;
                        this.entity.advancePaymentNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            addRow(){
                const item = { 
                    id: 'id_' + common.getFakeId(), 
                    estimate: { estimateCode: '', estimateDate: '', staff: {fullName: '' } },
                    orderId: 0,
                    order: { orderCode: '' },
                    estimateAmount: 0,
                    advancePaymentAmount: 0
                };
                this.entity.estimateList.push(item);
            },

            //Estimate Request
            delRow(item){
                const index = this.entity.estimateList.findIndex(x => x.id == item.id);
                this.entity.estimateList.splice(index, 1);
                for(var i = 0; i < this.entity.details.length; i ++){
                    let estimateId = this.entity.details[i].estimateId;
                    if(item.estimateId === estimateId){
                        this.entity.details = this.entity.details.filter(obj => !(obj.estimateId == item.estimateId));
                    }
                }

                this.calTotal();
            },

            closeEstimate(item){
                const selected = this.entity.estimateList.findIndex(x => x.id == this.selectedId);
                this.entity.estimateList[selected].estimate = item;
                this.entity.estimateList[selected].estimateId = item.id;
                this.entity.estimateList[selected].orderId = item.orderId;
                this.entity.estimateList[selected].estimateCode = item.estimateCode;
                this.entity.estimateList[selected].order = item.order;
                this.entity.estimateList[selected].estimateDate = item.estimateDate;
                this.entity.estimateList[selected].estimateAmount = item.estimateAmount;
                this.entity.estimateList[selected].advancePaymentAmount = item.advancePaymentAmount;
                this.selectedId = '';
                this.getEstimateById(item.id);
            },

            getEstimateById(id){
                this.setLoading(true);
                orderHeavyEstimateService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            if(parseFloat(detail.type) == 3){
                                detail.advancePaymentPrice = 0;
                                detail.advancePaymentVat = 0;
                                detail.advancePaymentAmount = 0;
                            }
                            else{
                                detail.advancePaymentPrice = detail.estimatePrice;
                                detail.advancePaymentVat = detail.estimateVat;
                                detail.advancePaymentAmount = detail.estimateAmount;
                            }
                            
                            this.entity.details.push(detail);
                        }
                        this.calTotal();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.estimateList.close();});
            },

            openEstimateList(id){
                this.selectedId = id;
                this.$refs.estimateList.open(this.orderType);
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(50).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairAdvancePayment), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.estimateList.length > 0){
                    this.entity.estimateList = this.entity.estimateList.filter(item => !(item.estimateId == 0));
                    this.entity.estimateList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.estimateList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập tạm ứng chi phí làm hàng");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập nhu nội dung tạm ứng");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                orderHeavyAdvancePaymentService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/order-heavy-advance-payment');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                orderHeavyAdvancePaymentService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/order-heavy-advance-payment');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderHeavyAdvancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        for(let i=0; i< response.data.estimateList.length; i++){
                            this.estimateList.push({id: response.data.estimateList[i].estimateId, estimateCode: response.data.estimateList[i].estimate.estimateCode});
                        }
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/order-heavy-advance-payment?orderType=' + this.orderType);
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }   
                },
                advancePaymentCode: { required },
                advancePaymentDate: { required },
                note: { required },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>
